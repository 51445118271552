// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import BaseApi from '@src/API/BaseApi';

export const getMessages = createAsyncThunk('appDescussions/getDiscussion', async ({id, type}) => {
  const response = await BaseApi().get(`/api/${type}-descussion/${id}?pageSize=200`);
  const resp = {
    [id]: response?.data?.data?.messages?.data || [],
    'type': type
  }
  return resp;
});

export const sendMsg = createAsyncThunk('appDescussions/sendMsg', async ({id, message, type}, { dispatch }) => {
  const response = await BaseApi().post(`/api/${type}-descussion/${id}/send`, {
    'message':message,
    'id':id,
  });
  await dispatch(getMessages({id, type}));
  return response.data;
});

export const appDescussionsSlice = createSlice({
  name: 'appDescussions',
  initialState: {
    messages:{}
  },
  reducers: {
    resetMessages: (state, action) => {
      if (action.payload && state.messages[action?.payload]) {
        state.messages[action?.payload] = {}
        return;
      }
      state.messages = {}
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMessages.fulfilled, (state, action) => {
        const { type, ...payload } = action?.payload;
        if (type) {
          state.messages[type] = payload;
        }
      })
      .addCase(sendMsg.rejected, (state, action) => {
      
      })
  }
});

export const {resetMessages} = appDescussionsSlice.actions;
export default appDescussionsSlice.reducer;

