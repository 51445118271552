import axios, { Axios } from "axios";
import { getBaseUrl, getUserToken } from "../helpers";
import EncryptionUtils from "@helpers/EncryptionUtils";
import { isEmpty, isString } from "lodash";

class BaseApiService {

  apiInstance = undefined;

  create = (config) => {
    this.init(config);
    return this.apiInstance;
  }

  init = (config) => {
    //init request config
    this.config = { ...config };
    this.instance = axios.create(this.config);

    this.addHeader("Accept", "application/json");
    this.addHeader("Content-Type", "application/json");
    //get token and add it to the request
    this.updateBaseUrl();
    //update request base url
    this.updateToken();
    //apply transformers
    this.applyTransformers();
    //apply interceptors
    this.applyInterceptors();

    this.apiInstance = this.instance;
    return this;
  };

  getApiInstace = () => {
    return this.apiInstance;
  }

  applyTransformers = () => {
    this.instance.defaults.transformResponse = [
      ...axios.defaults.transformResponse,
      (data, headers) => {
        if (headers["content-type"] === "application/json") {
          // Check if the response data is a valid JSON string
          if (isString(data) && data.trim() !== "") {
            try {
              data = JSON.parse(data);
            } catch (e) {
              console.error(e);
            }
          }
        }
        return data;
      }
    ];

    /* this.defaults.transformRequest = [
    ...axios.defaults.transformRequest,
      (data, headers) => {
        // Check if the data is an object
        if (typeof data === 'object' && data !== null && headers['content-type'] == "application/json") {
          // Convert the object to a JSON string
          return JSON.stringify(data);
        }
        return data;
      },
      
    ]; */
  };

  applyInterceptors = () => {
    this.instance.interceptors.request.use(this.onRequest, this.onRequestError);
    this.instance.interceptors.response.use(this.onResponse, this.onResponseError);
  }

  updateBaseUrl = () => {
    const baseUrl = getBaseUrl();
    if (isString(baseUrl) && !isEmpty(baseUrl)) {
      this.config.baseURL = baseUrl;
    }
  };

  updateToken = () => {
    const token = getUserToken();
    if (isString(token) && !isEmpty(token)) {
      this.addHeader("Authorization", `Bearer ${token}`);
    }
  };

  encryptRequestProps = (keys = []) => {
    if (Array.isArray(keys) && keys.length > 0) {
      this.keys = keys;
      //enable encryption
      this.addHeader("Has-Encrypted-Data", true);
      //set field encrypted
      this.addHeader("Encrypted-Props", keys?.join(","));
    }
    return this;
  };

  addHeader = (headerName, headerValue) => {
    const oldHeaders = this.config.headers || {};
    this.config.headers = {
      ...oldHeaders,
      [headerName]: headerValue
    };
    return this;
  };

  stringifyData = () => {
    if (this.config.data && !isString(this.config.data)) {
      this.config.data = JSON.stringify(this.config.data);
    }
  };

  applyEncryption = () => {
    try {
      if (!Array.isArray(this.keys)) {
        return;
      }
      for (let key of this.keys) {
        if (this.config.data.hasOwnProperty(key)) {
          this.config.data[key] = EncryptionUtils.encrypt(this.config.data[key]);
        }
      }
    } catch (e) {
      throw e;
    }
  };

  decryptResponseProps = (response) => {
    try {
      //
      if (response && response.data && response.headers) {
        const hasEncryptedData = response.headers["has-encrypted-data"] == 1;
        const hasEncryptedProps = "encrypted-props" in response.headers;

        if (hasEncryptedData && hasEncryptedProps) {
          if (
            !isEmpty(response.data) &&
            response.data.hasOwnProperty("data") &&
            Object.keys(response.data.data).length > 0
          ) {
            const propsToDecrypt = response.headers["encrypted-props"]?.split(",") || [];
            for (let key of propsToDecrypt) {
              const k = key?.trim();
              if (k?.length > 0 && response.data?.data?.hasOwnProperty(k)) {
                response.data.data[k] = EncryptionUtils.decrypt(response.data?.data[k]);
              }
            }
          }
        }
      }
    } catch (e) {
      throw e;
    }
    return response;
  };

  onRequest = (config) => {
    this.config = { ...config, ...this.config };
    //apply request props encryption
    this.applyEncryption();
    //stringify request payload
    this.stringifyData();
    //process request and handle response
    return this.config;
  };

  onRequestError = (error) => {
    return Promise.reject(error);
  };

  onResponse = (response) => {
    const data = this.decryptResponseProps(response);
    return data;
  };

  onResponseError = (error) => {
    return Promise.reject(error);
  };
}

const BaseApi = (config = {}) => {
  return new BaseApiService().create(config);
};


export default BaseApi;
