export const links = Object.freeze({

  //public 
  "countries": "api/list/countries",

  //dropdown 
  "cities": "api/list/cities",
  "areas": "api/list/areas",
  "branches": "api/list/branches",
  "departments": "/api/list/departments",
  "currencies": "/api/list/currencies",
  "company-banks-accounts": "/api/list/company-banks-accounts",
  "treasuries": "/api/list/treasuries",
  "taxes-types": "/api/list/taxes-types",
  "expense-types": "/api/list/expense-types",
  "payment-methods": "/api/list/payment-methods",
  "payment-terms": "/api/list/payment-terms",
  // "official-receipt": "/api/list/official-receipt",
  "timesheet-categories": "/api/list/timesheet-categories",
  // "timesheet-sub-categories": "/api/list/timesheet-sub-categories",

  //products
  "product-categories": "/api/list/product-categories",
  "product-brands": "/api/list/product-brands",
  "product-models": "/api/list/product-models",
  "vendors-products": "/api/list/vendors-products",
  "clients-products": "/api/list/clients-products",

  //services
  "company-services-categories": "/api/list/company-services-categories",
  "vendor-services-categories": "/api/list/vendor-services-categories",
  "vendors-services": "/api/list/vendors-services",
  "clients-services": "/api/list/clients-services",

  "assets-categories": "/api/list/assets-categories",
  "packing-types": "/api/list/packing-types",

  //units
  "measurement-units-types": "/api/list/measurement-units-types",
  "measurement-units": "/api/list/measurement-units",

  //clients module
  "clients-list": "/api/list/clients",
  "leads-list": "/api/list/leads",
  "clients-leads": "/api/list/clients-leads",

  //vendors module
  "vendors-categories": "/api/list/vendors-categories",

  //marketing module
  "advertising-tools": "/api/list/advertising-tools",
  "sales-agents": "/api/list/sales-agents",

  //purchases
  "purchase-order-types": "/api/list/purchase-order-types",
  "purchase-requests": "/api/list/purchase-requests",

  //rfqs
  "rfqs-list": "/api/list/rfqs-list",

  "custody-senders": "/api/list/custody-senders",
  "roles": "/api/list/roles",
  "assets": "/api/list/assets",
  "sales-invoices": "/api/list/sales-invoices",
  "vendors-list": "/api/list/vendors",
  "employees-list": "/api/list/employees",
  //
  "tenders": "/api/list/tenders",
  "client-orders": "/api/list/client-orders",
  "vendor-orders": "/api/list/vendor-orders",
  "client-tenders": "/api/list/client-tenders",
  "company-sectors": "/api/list/company-sectors",

  //users Module
  'users-list' : 'api/list/users',
  'users-filtered' : 'api/list/users-filtered'
});