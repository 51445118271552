// ** Third Party Components
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export const handleConfirmText = (
  title,
  text,
  type,
  buttons = { confirm: 'Yes', cancel: 'No' },
  options = {},
  confirmCallback,
  cancelCallback
) => {
  return MySwal.fire({
    title: title,
    text: text,
    icon: type,
    showCancelButton: true,
    confirmButtonText: buttons.confirm,
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-outline-danger ms-1'
    },
    buttonsStyling: false
  }).then(function (result) {
    if (result.value) {
      confirmCallback();
    } else {
      cancelCallback();
    }
  });
};

export const handleConfirmAlert = (opt, component = null) => {
  const defaultOptions = {
    title: undefined,
    text: undefined,
    type: undefined,
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-danger ms-1',
    html: component ?? null,
    buttons: {
      confirm: 'Yes',
      cancel: 'No'
    },
    options: {},
    confirmCallback: () => false,
    cancelCallback: () => false
  };
  let options = { ...defaultOptions, ...opt };
  //
  if (typeof options.type == 'undefined') {
    return false;
  }
  //
  return MySwal.fire({
    title: options.title,
    text: options.text,
    icon: options.type,
    showCancelButton: true,
    confirmButtonText: options.buttons.confirm,
    html: options.html,
    customClass: {
      confirmButton: options.confirmButton,
      cancelButton: options.cancelButton
    },
    buttonsStyling: false
  }).then(function (result) {
    if (result.value) {
      if (typeof options.confirmCallback == 'function') {
        options.confirmCallback();
      }
    } else {
      if (typeof options.cancelCallback == 'function') {
        options.cancelCallback();
      }
    }
  });
};

const getClassNameByType = (type) => {

  switch (type) {
    case 'success':
      
      return 'text-success';

    case 'error':
      
      return 'text-danger';

    case 'info' :
    case 'question' :
      
      return 'text-info';

    default:
      return 'text-warning'
  }
};

export const handleConfirmCancel = (
  title,
  text = '',
  type = 'warning',
  confirmCallback = () => false,
  cancelCallback = () => false,
  buttons = { confirm: 'Yes', cancel: 'No' },
  options = {
    confirmClassNames : '',
    cancelClassNames : ''
  }
) => {
  const selectedClass = getClassNameByType(type);

  return MySwal.fire({
    title: `<span class="${selectedClass} fs-3">${title}</span>`,
    html: `<span class="fs-6 fw-bold text-secondary">${text}</span>`,
    //text: text,
    icon: type ? type : false,
    showCancelButton: buttons.cancel ? true : false,
    showConfirmButton: buttons.confirm ? true : false,
    confirmButtonText: buttons.confirm ? buttons.confirm : '',
    cancelButtonText: buttons.cancel ? buttons.cancel : '',
    customClass: {
      confirmButton: `btn btn-outline-secondary ${options?.confirmClassNames}`,
      cancelButton: `btn btn-outline-secondary ms-1 ${options?.cancelClassNames}`
    },
    buttonsStyling: false
  }).then(function (result) {
    if (result && result.value) {
      confirmCallback();
    } else if (result.dismiss === MySwal.DismissReason.cancel && typeof cancelCallback === 'function') {
      cancelCallback()
    }
  });
};
