import { createSlice } from "@reduxjs/toolkit";

export const coreFormSlice = createSlice({
  name: "coreForm",
  initialState: {
    isResetting: false,
    showLoadingIdicator: false
  },
  reducers: {
    setIsResetting: (state, action) => {
      state.isResetting = action.payload;
    },
    setShowLoadingIndicator: (state, action) => {
      state.showLoadingIdicator = action.payload;
    }
  }
});

export const { setIsResetting, setShowLoadingIndicator } = coreFormSlice.actions;
export default coreFormSlice.reducer;
