import axios from "axios";
import { toast } from "react-toastify";
import { getBaseUrl, getUserToken } from "../helpers";
import { isEmpty, isObject, isString } from "lodash";
import EncryptionUtils from "@helpers/EncryptionUtils";

var cancelTokenSource = axios.CancelToken.source();
class CrudApiService {

  apiInstance = undefined;

  create = (config) => {
    this.init(config);
    return this.apiInstance;
  }

  init = (config) => {
    this.config = {
      baseURL: getBaseUrl(),
      useFormData: true,
      disableProgress: false,
      keys: [],
      requiredPayloadKeys: ["attachments"],
      ...config
    };
    //init instance
    this.instance = axios.create(this.config);
    //handle request cancellation
    this.toastId = this.initToast(this.config.toastTitle || "User Submission");
    //apply transformers
    this.applyTransformers();
    //bind interceptors
    this.applyInterceptors();
    //init api instance
    this.apiInstance = this.instance;
    //
    return this;
  };

  getApiInstace = () => {
    return this.apiInstance;
  };

  applyTransformers = () => {
    this.instance.defaults.transformResponse = [
      ...axios.defaults.transformResponse,
      (data, headers) => {
        if (headers["content-type"] === "application/json") {
          // Check if the response data is a valid JSON string
          if (typeof data === "string" && data.trim() !== "") {
            try {
              return JSON.parse(data);
            } catch (e) {
              // If the data is not a valid JSON string, return the original data
              return data;
            }
          }
        }

        return data;
      }
    ];
  };

  applyInterceptors = () => {
    this.instance.interceptors.request.use(this.onRequest, this.onRequestError);
    this.instance.interceptors.response.use(this.onResponse, this.onResponseError);
  }

  initToast = (title) => {
    return toast(
      <div className="row w-100">
        <div className="row w-100">
          <p className="toast-title fs-4 fw-bold">
            {title} <span id="progress-indicator">(0%)</span>
          </p>
        </div>
        <div className="row w-100 flex-grow-1">
          <small className="text-dark">
            Your files are being uploaded. This may take a moment, please stand by!
          </small>
          <small className="text-muted">*uploading progress depends on your internet speed.</small>
        </div>
        <div className="row justify-content-end w-100 flex-grow-1">
          <small
            className="d-flex justify-content-end text-danger fw-bold cursor-pointer"
            onClick={() => {
              this.abortXhrRequest();
            }}
          >
            Cancel
          </small>
        </div>
      </div>,
      {
        autoClose: false,
        closeButton: false,
        closeOnClick: false,
        draggable: false,
        icon: false,
        type: "warning",
        className: "d-none",
        toastId: "form-submittion",
        timeout: 2300
      }
    );
  };

  setToastTitle = (title) => {
    this.config.toastTitle = title;
    return this;
  };

  setEncryptedKeys = (keys = []) => {
    this.config.keys = keys;
    return this;
  };

  setUseFormData = (status = false) => {
    this.config.useFormData = status;
    return this;
  };

  setDisableProgressBar = (status = false) => {
    this.config.disableProgress = status;
    return this;
  };

  setRequiredPayloadKeys(keys = []) {
    this.config.requiredPayloadKeys = keys;
    return this;
  }

  abortXhrRequest = () => {
    if (confirm("Are you sure you want to cancel the form submission request?")) {
      window.onbeforeunload = null;
      cancelTokenSource.cancel("Request canceled by the user.");
      toast.update(this.toastId, {
        className: "d-none",
        progress: 0
      });
      setTimeout(() => {
        cancelTokenSource = axios.CancelToken.source();
      }, 500);
    }
  };

  handleProgressBar = (progressEvent) => {
    try {
      if (progressEvent.loaded === progressEvent.total) {
        setTimeout(() => {
          toast.update(this.toastId, {
            className: "d-none",
            progress: 100
          });
        }, 800);
        return;
      }

      window.onbeforeunload = () => "Please wait until the data submitted!";
      const percentage = (progressEvent.loaded * 100) / progressEvent.total;
      toast.update(this.toastId, {
        className: "d-block",
        progress: percentage / 100
      });
      const indicator = document.getElementById("progress-indicator");
      indicator.innerText = `(${+percentage.toFixed(2)}%)`;
    } catch (error) {
      console.error(error);
    } finally {
      if (progressEvent?.loaded === progressEvent?.total) {
        window.onbeforeunload = null;
      }
    }
  };

  handleRequestToken = () => {
    const token = getUserToken();
    if (!isEmpty(token) && isString(token)) {
      this.config.headers = {
        ...this.config.headers,
        Authorization: `Bearer ${token}`
      };
    }
  };


  /* isPlainObject(value) {
    if (typeof value !== 'object' || value === null) {
      return false;
    }
    
    const prototype = Object.getPrototypeOf(value);
    return (prototype === null || prototype === Object.prototype || Object.getPrototypeOf(prototype) === null) && !(Symbol.toStringTag in value) && !(Symbol.iterator in value);
  }; */

  convertToFormData(obj, formData = new FormData(), parentKey = '', requiredKeys = ['']) {

    const isPlainObject = (value) => {
      return value &&
        typeof value === 'object' &&
        value.constructor === Object &&
        Object.getPrototypeOf(value) === Object.prototype;
    };

    const appendToFormData = (key, value) => {
      if (value === null || value === undefined) {
        formData.append(key, '');
      } else if (value instanceof File) {
        formData.append(key, value);
      } else if (value instanceof Date) {
        formData.append(key, value.toISOString());
      } else if (typeof value === 'boolean') {
        formData.append(key, value ? 'true' : 'false');
      } else {
        formData.append(key, value.toString());
      }
    };

    Object.entries(obj).forEach(([key, value]) => {
      const propName = parentKey ? `${parentKey}[${key}]` : key;

      if (Array.isArray(value)) {
        if (value.length === 0 && this.config.requiredPayloadKeys?.includes(propName)) {
          formData.append(`${propName}[]`, '');
        } else {
          value.forEach((item, index) => {
            const itemKey = `${propName}[${index}]`;
            isPlainObject(item)
              ? this.convertToFormData(item, formData, itemKey)
              : appendToFormData(itemKey, item);
          });
        }
      } else if (isPlainObject(value)) {
        this.convertToFormData(value, formData, propName);
      } else {
        appendToFormData(propName, value);
      }
    });

    return formData;
  }

  /* convertToFormData(obj, formData = new FormData(), parentKey = '') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const propName = parentKey ? `${parentKey}[${key}]` : key;
  
        if (value === null || value === undefined) {
          formData.append(propName, '');
        } else if (typeof value === 'object' && !(value instanceof File) && !(value instanceof Date)) {//this.isPlainObject(value)
          this.convertToFormData(value, formData, propName);
        } else if (Array.isArray(value)) {
          if(value.length === 0) {
            this.appendFormData(formData, `${propName}[]`, '');
          } else {
            value.forEach((item, index) => {
              this.appendFormData(formData, `${propName}[${index}]`, item);
            });
          }
        } else {
          this.appendFormData(formData, propName, value);
        }
      }
    }
  
    return formData;
  }; */

  handleFormData = () => {
    if (this.config.useFormData == true && isObject(this.config?.data)) {
      this.config.data = this.convertToFormData(this.config?.data);
      this.config.headers = {
        ...this.config.headers,
        "Content-Type": "multipart/form-data"
      };

      if (this.config.disableProgress == false) {
        this.config.onUploadProgress = this.handleProgressBar;
        this.config.cancelToken = cancelTokenSource.token;
      }
    }
    return this;
  };

  applyRequestEncryption = () => {
    if (Array.isArray(this.config.keys) && this.config.keys?.length > 0) {
      this.config.headers = {
        ...this.config.headers,
        "Has-Encrypted-Data": true,
        "Encrypted-Props": this.config.keys.join(",")
      };

      for (let key of this.config.keys) {
        if (isString(key) && !isEmpty(key) && this.config?.data.hasOwnProperty(key)) {
          this.config.data[key] = EncryptionUtils.encrypt(this.config.data[key]);
        }
      }
    }
    return this;
  };

  decryptResponseProps = (response) => {
    if (response && response.data && response.data?.data && response.headers) {
      const hasEncryptedData = response.headers["has-encrypted-data"] == 1;
      const hasEncryptedProps = "encrypted-props" in response.headers;

      if (hasEncryptedData && hasEncryptedProps) {
        const propsToDecrypt = response.headers["encrypted-props"]?.split(",") || [];

        for (let key of propsToDecrypt) {
          const k = key?.trim();
          if (isString(k) && !isEmpty(k) && response?.data?.data.hasOwnProperty(k)) {
            response.data.data[k] = EncryptionUtils.decrypt(response.data.data[k]);
          }
        }
      }
    }
    return response;
  };

  onRequest = (config) => {
    this.config = { ...config, ...this.config };
    // handle request token
    this.handleRequestToken();
    // apply request encryption
    this.applyRequestEncryption();
    // convert request into form data
    this.handleFormData();
    //process request and handle response
    return this.config;
  };

  onRequestError = (error) => {
    return Promise.reject(error);
  };

  onResponse = (response) => {
    const data = this.decryptResponseProps(response);
    return data;
  };

  onResponseError = (error) => {
    return Promise.reject(error);
  };

}

const CrudApi = (config = {}) => {
  return new CrudApiService().create(config);
};

export default CrudApi;
