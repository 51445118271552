import { Font } from "@react-pdf/renderer";
const PdfFonts = [
  {
    family: "Vazirmatn",
    fonts: [
      {
        src: "/fonts/Vazirmatn.ttf"
      },
      {
        src: "/fonts/Vazirmatn-Bold.ttf",
        fontWeight: "bold"
      },
      {
        src: "/fonts/Vazirmatn-Medium.ttf",
        fontWeight: "medium"
      },
      {
        src: "/fonts/Vazirmatn-SemiBold.ttf",
        fontWeight: "semibold"
      }
    ]
  },
  {
    family: "Nunito",
    fonts: [
      {
        src: "/fonts/Nunito.ttf"
      },
      {
        src: "/fonts/Nunito-Bold.ttf",
        fontWeight: "bold"
      },
      {
        src: "/fonts/Nunito-SemiBold.ttf",
        fontWeight: "semibold"
      },
      {
        src: "/fonts/Nunito-Regular.ttf",
        fontWeight: "normal"
      }
    ]
  }
];
export const registerPdfFonts = () => PdfFonts.map((font) => Font.register({ ...font }));
