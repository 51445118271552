// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hanleResponseErrors, loadPage } from "@helpers/Actions";
import BaseApi from "@baseApi";
import * as moment from "moment";

export const handleUserLogin = createAsyncThunk(
  "loginUser/login",
  async ({ email, password }, { fulfillWithValue, rejectWithValue }) => {
    const response = await BaseApi().post("/api/auth/login", { email, password })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    if (response?.status === 200 && response?.data?.data?.access_token) {
      return fulfillWithValue(response?.data?.data);
    }
    return rejectWithValue(response);
  }
);

export const handleUserLogout = createAsyncThunk(
  "loginUser/logout",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const response = await BaseApi().get("/api/auth/logout")
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    if (response?.status === 200) {
      return fulfillWithValue(response?.data);
    }
    return rejectWithValue(response);
  }
);

export const authMe = createAsyncThunk(
  "auth/authMe",
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    const response = await BaseApi().get("/api/auth/me");
    if (response?.status === 200) {
      // dispatch(updateCompany(response?.data?.data?.company));
      return fulfillWithValue(response?.data?.data);
    }
    return rejectWithValue(response);
  }
);

const updateUserAndPermissions = (state, action) => {
  const perrmissions = action.payload?.permissions || []
  state.permissions = [...perrmissions , 'read_profile','edit_profile'];
  state.user = action.payload;
};

const clearUserData = (state) => {
  state.user = {};
  state.isLogin = false;
  state.loginAt= undefined;
  state.permissions = [];
};

const ignoredErrors = ["Network Error"];

export const loginUserSlice = createSlice({
  name: "loginUser",
  initialState: {
    user: {},
    permissions: [],
    isLogin: false,
    loginAt: undefined,
    access_token: undefined,
    refresh_token: undefined,
    remember_token: undefined
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleUserLogin.fulfilled, (state, action) => {
        const user  = action.payload;
        state.isLogin = true;
        updateUserAndPermissions(state, action);
        state.loginAt = moment();
        state.access_token = user.access_token;
        state.refresh_token = user.refresh_token;
        state.remember_token = user.remember_token;
      })
      .addCase(handleUserLogin.rejected, (state, action) => {
        state.isLogin = false;
        state.user = {};
        state.permissions = [];
        state.loginAt = undefined;
        state.access_token = undefined;
        state.refresh_token = undefined;
        state.remember_token = undefined;
      })
      .addCase(handleUserLogout.fulfilled, (state, action) => {
        clearUserData(state);
      })
      .addCase(handleUserLogout.rejected, (state, action) => {
        clearUserData(state);
      })
      .addCase(authMe.fulfilled, (state, action) => {
        updateUserAndPermissions(state, action);
      })
      .addCase(authMe.rejected, (state, action) => {
        /* const errorMessage = action.error?.message;
        if (!ignoredErrors.includes(errorMessage)) {
          clearUserData(state);
        } */
      });
  }
});

export default loginUserSlice.reducer;
