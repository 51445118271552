import { domNotify } from "@domNotify";

const Action = {};
Action.update = {};
Action.add = {};
Action.save = {};
Action.delete = {};
Action.request = {};
Action.reject = {};

Action.update.success = (message) => {
  domNotify({
    type: "success",
    title: "Successfully Updated",
    text: message || "You have Successfully Updated"
  });
};

Action.update.error = (message) => {
  domNotify({
    type: "error",
    title: "Processing Error!",
    text: message || "An Eror Has Occurred While Proccessing."
  });
};

Action.add.success = (duplicate) => {
  domNotify({
    type: "success",
    title: duplicate ? "Data Duplicated." : "Data Submitted.",
    text: duplicate ? "You have successfully Duplicated!" : "Data Submitted Successfully."
  });
};

Action.add.error = (err) => {
  domNotify({
    type: "error",
    title: "Processing Error!",
    text: err?.message || "An Eror Has Occurred While Proccessing."
  });
};

Action.request.success = (message) => {
  domNotify({
    type: "success",
    title: "Requested Successfully",
    text: message || "Request Submitted Successfully."
  });
};

Action.request.error = (err) => {
  domNotify({
    type: "error",
    title: "Processing Error!",
    text: err?.message || "An Eror Has Occurred While Proccessing."
  });
};

Action.reject.success = (message) => {
  domNotify({
    type: "success",
    title: "Rejected Successfully",
    text: message || "Record Rejected Successfully."
  });
};

Action.reject.error = (err) => {
  domNotify({
    type: "error",
    title: "Processing Error!",
    text: err?.message || "An Eror Has Occurred While Proccessing."
  });
};

Action.save.success = (message) => {
  domNotify({
    type: "success",
    title: "Save Form.",
    text: message || "All Data Saved Successfully!"
  });
};

Action.delete.success = (message) => {
  domNotify({
    type: "success",
    title: "Successfully Deleted",
    text: message || "You Have Successfully Deleted The Record(s)."
  });
};
Action.delete.error = (message) => {
  domNotify({
    type: "error",
    title: "Not Deleted",
    text: message || "This Record Failed to Delete !"
  });
};

Action.errorMessage = (error) => {
  return Object?.values(error?.split(",") || {})
    .flatMap((messages) => messages)
    .join("<div></div>");
};

Action.preparePdf = () => {
  domNotify({
    type: "info",
    title: "Preparing PDF...",
    text: "PDF will be generated soon.",
    toastOptions: {
      autoClose: 2500
    }
  });
};

export { Action };
