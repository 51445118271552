import CryptoJS from "crypto-js";
//import phpUnserialize from "phpunserialize";

const EncryptionUtils = {
  encrypt: (data) => {
    let result = undefined;
    try {
      let iv = CryptoJS.lib.WordArray.random(16);
      let key = CryptoJS.enc.Base64.parse(`${process.env.REACT_APP_KEY}`);
      let options = {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        serialize: true
      };
      let encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key, options);
      encrypted = encrypted.toString();
      iv = CryptoJS.enc.Base64.stringify(iv);
      result = {
        iv: iv,
        value: encrypted,
        mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
      };
      result = JSON.stringify(result);
      result = CryptoJS.enc.Utf8.parse(result);
      result = CryptoJS.enc.Base64.stringify(result);
    } catch (e) {
      throw e;
    }
    //console.log(result)
    return result;
  },
  decrypt: (encryptStr) => {
    try {
      encryptStr = CryptoJS.enc.Base64.parse(encryptStr);
      let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
      encryptData = JSON.parse(encryptData);
      const iv = CryptoJS.enc.Base64.parse(encryptData.iv);
      let decrypted = CryptoJS.AES.decrypt(
        encryptData.value,
        CryptoJS.enc.Base64.parse(`${process.env.REACT_APP_KEY}`),
        {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        }
      );
      decrypted = CryptoJS.enc.Utf8.stringify(decrypted);
      return EncryptionUtils.parseDecrypedData(decrypted);
    } catch (e) {
      throw e;
    }

    return null;
  },
  isEncrypted: (data) => {
    //TODO: may this needed when we need to check wether string is encrypted or not
    return false;
  },
  parseDecrypedData: (data) => {
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
  }
};

export default EncryptionUtils;
