import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleConfirmCancel } from "@helpers/ConfirmAlerts";
import { domNotify } from "@domNotify/index";
import { filterBuilder, hanleResponseErrors } from "@helpers/Actions";
import CrudApi from "@crudApi";
import BaseApi from "@baseApi";
import { Action } from "@action";
import { getOptions } from "@components/ReduxComponents/SingleSelect/store";

export const getData = createAsyncThunk("systemTypes/getData", async (params) => {
  const { type } = params;
  const p = filterBuilder(params);
  if (!type) {
    throw new Error("Error Invalid type");
  }
  const response = await BaseApi().get(`/api/system-configs/${type}?${p}`);
  return {
    params,
    data: response.data.data.list.data,
    total: response.data.data.list.total
  };
});

export const addSystemType = createAsyncThunk(
  "systemTypes/addSystemType",
  async (params, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    const { type, items } = params;
    const data = items?.map((item) => ({ ...item, status: true }));
    const response = await BaseApi().post(`/api/system-configs/${type}`, {
        items: data
      })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    await dispatch(getData(getState().systemTypes.params));
    await dispatch(
      getOptions({
        type: type
      })
    )
    return response?.status === 200
      ? fulfillWithValue(response?.data)
      : rejectWithValue(response.data);
  }
);

export const updateSystemType = createAsyncThunk(
  "systemTypes/editSystemType",
  async (params, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    const { type, id, ...restParams } = params;
    const response = await BaseApi().put(`/api/system-configs/${type}/${id}`, {
        ...restParams
      })
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    await dispatch(getData(getState().systemTypes.params));
    return response?.status === 200
      ? fulfillWithValue(response?.data)
      : rejectWithValue(response.data);
  }
);

export const updateStatus = createAsyncThunk(
  "systemTypes/updateStatus",
  async (params, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    const { type, id, status } = params;
    const response = await BaseApi().put(`/api/system-configs/${type}/${id}`, {
      status: status
    })
    .then((res) => res)
    .catch((e) => hanleResponseErrors(e));
    await dispatch(getData(getState().systemTypes.params));
    return response?.status === 200
      ? fulfillWithValue(response?.data)
      : rejectWithValue(response.data);
  }
);

export const deleteSystemType = createAsyncThunk(
  "systemTypes/deleteSystemType",
  async (params, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
    const { type, id } = params;
    const response = await BaseApi().delete(`/api/system-configs/${type}/${id}`)
      .then((res) => res)
      .catch((e) => hanleResponseErrors(e));
    await dispatch(getData(getState().systemTypes.params));
    return response?.status === 200
      ? fulfillWithValue(response?.data)
      : rejectWithValue(response.data);
  }
);

export const handleDelete = createAsyncThunk(
  "systemTypes/handleDelete",
  async (params, { dispatch }) => {
    handleConfirmCancel(
      "Delete",
      "This item will be deleted, kindly confirm?",
      'error',
      () => {
      dispatch(deleteSystemType(params));
    },null,
    { cancel:'Cancel' , confirm:'Delete'},
    {confirmClassNames:'btn-danger'}
  )
  }
);

export const submitFile = createAsyncThunk(
  "systemTypes/submitFile",
  async (params, { dispatch, getState }) => {
    const { type, file } = params;
    await CrudApi().post(`/api/system-configs/${type}/import`, {
      type,
      file
    });
    await dispatch(getData(getState().systemTypes.params));
    return "success";
  }
);

export const systemTypesSlice = createSlice({
  name: "systemTypes",
  initialState: {
    isReady: false,
    data: [],
    total: 1,
    params: {},
    allData: [],
    systemType: {}
  },
  reducers: {
    resetSystemType: (state, action) => {
      return {
        isReady: false,
        data: [],
        total: 1,
        params: {},
        allData: [],
        systemType: {}
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.total;
      })
      .addCase(getData.rejected, (state, action) => {
        state.data = [];
        state.total = 1;
      })
      .addCase(deleteSystemType.fulfilled, (state, action) => {
        domNotify({
          type: "success",
          title: "Deleted Successfully",
          text: "Record Deleted Successfully"
        });
      })
      .addCase(deleteSystemType.rejected, (state, action) => {
        domNotify({
          type: "error",
          title: "UnSuccessfully Deleted",
          text:
            Action.errorMessage(action.payload.message) || "Error Occurred While Record Deleting"
        });
      })
      .addCase(updateSystemType.fulfilled, (state, action) => {
        domNotify({
          type: "success",
          title: "Successfully Updated",
          text: Action.errorMessage(action.payload.message) || "Record Updated Successfully"
        });
      })
      .addCase(updateSystemType.rejected, (state, action) => {
        domNotify({
          type: "error",
          title: "UnSuccessfully Updated",
          text: Action.errorMessage(action?.payload?.message) || "Error Occurred While Updating"
        });
      })
      .addCase(addSystemType.fulfilled, (state, action) => {
        domNotify({
          type: "success",
          title: "Successfully Added",
          text: action.payload?.messages[0] || "Records Submitted Successfully"
        });
      })
      .addCase(addSystemType.rejected, (state, action) => {
        domNotify({
          type: "error",
          title: "UnSuccessfully Added",
          text:
            Action.errorMessage(action.payload?.message) ||
            "Error Occurred While Submitting Records"
        });
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        domNotify({
          type: "success",
          title: "Updated Successfully",
          text: "Status Updated Successfully"
        });
      })
      .addCase(updateStatus.rejected, (state, action) => {
        domNotify({
          type: "error",
          title: "UnSuccessfully Update Status",
          text:
            Action.errorMessage(action.payload.message) || "Error Occurred While Change Record Status"
        });
      })
  }
});
export const { resetSystemType } = systemTypesSlice.actions;
export default systemTypesSlice.reducer;
